import { Icon } from "@iconify/react";
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import ProfileBox from "../../partials/miawwhalla/ProfileBox";
import User from "../../partials/miawwhalla/User";

function MiawwhallaEventDetail() {
  return (
    <>
      <Toaster />
      {/* Show on mobile */}
      <div className="fixed inset-0 bg-white z-50 flex justify-center items-center lg:hidden">
        <div className="text-center px-4">
          <Icon icon="akar-icons:circle-x" className="w-10 h-10 mx-auto text-red-400" />
          <h2 className="font-cursive text-3xl mt-4">Sorry</h2>
          <p>Miawwhalla is only accessible via desktop</p>
          <Link to={`${process.env.PUBLIC_URL}/`} className="px-4 py-2 ml-2 mr-4 mt-6 bg-primary-800 text-white rounded inline-block font-cursive text-sm">
            Back to home
          </Link>
        </div>
      </div>

      {/* Show on desktop */}
      <div
        className="min-h-screen bg-cover bg-center overflow-hidden"
        style={{ backgroundImage: `url('${process.env.PUBLIC_URL}/images/miawwhalla/bg.webp')` }}
      >
        <div className="container mx-auto mb-16">
          <div className="flex justify-between items-start pt-6">
            <User className="mt-4" />
            <img src={`${process.env.PUBLIC_URL}/images/miawwhalla/logo.webp`} alt="Logo" className="h-32" />
          </div>
        </div>

        <div className="container mx-auto relative">
          <div className="bg-[#D2D5E2] rounded-lg border border-gray-600 absolute right-0 -top-10 -bottom-10 left-10"></div>
          <div className="grid grid-cols-2 items-center relative">
            <div>
              <img src="https://i.postimg.cc/BnNQ9wJG/thumb.webp" alt="Thumb" className="w-full" />
            </div>
            <div className="p-8">
              <h1 className="text-[#162365] text-4xl font-cursive mb-2">#001: Welcome KHUGS!</h1>
              <p className="mb-4">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Hic soluta, quidem deleniti suscipit atque ullam unde quasi itaque enim sed. Minima
                cum aperiam natus laborum quas excepturi est a fugiat. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Hic soluta, quidem deleniti
                suscipit atque ullam unde quasi itaque enim sed. Minima cum aperiam natus laborum quas excepturi est a fugiat.
              </p>
              <h4 className="text-[#162365] text-base font-cursive mb-4">Exp Date: 12-12-2022</h4>
              <h4 className="text-[#162365] text-base font-cursive mb-2">Reward</h4>
              <div className="w-16 h-16 rounded-lg bg-[#162365]"></div>
              <div className="flex justify-end -mt-8">
                <Link to={`${process.env.PUBLIC_URL}/miawwhalla/battle/1`}>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/miawwhalla/btn-join.webp`}
                    alt="Join"
                    className="h-24 transition-all hover:scale-105 active:scale-95 ml-auto"
                  />
                </Link>
              </div>
            </div>

            <div className="absolute -bottom-20">
              <Link to={`${process.env.PUBLIC_URL}/miawwhalla/event`}>
                <img
                  src={`${process.env.PUBLIC_URL}/images/miawwhalla/btn-back.webp`}
                  alt="Back"
                  className="h-24 transition-all hover:scale-105 active:scale-95"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MiawwhallaEventDetail;
