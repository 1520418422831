import { find } from "lodash";
import { createContext, useEffect, useState } from "react";
import { useAuthenticatedHttpClient } from "../utils/authenticated-api-call";

export const FavoriteCollectionsContext = createContext();

export const FavoriteCollectionsProvider = ({ children }) => {
  const auth = localStorage.getItem("x-auth-khuga");
  const metamaskAddress = localStorage.getItem("metamask_address");
  const { internalAPIClient } = useAuthenticatedHttpClient();
  const [favCollections, setFavCollections] = useState([]);
  const [selectedFavCollection, setSelectedFavCollection] = useState();

  useEffect(() => {
    const checkCollections = async () => {
      internalAPIClient
        .get(`${process.env.REACT_APP_KHUGA_API_BASE_URL}/nft-ownerships/${metamaskAddress}/favorites`)
        .then((res) => {
          const data = res?.data?.data ?? [];
          setFavCollections(data);

          const profilePicture = find(data, (i) => i.metadata.id === JSON.parse(localStorage.getItem("x-user-khuga"))?.avatar_id);
          if (profilePicture) {
            setSelectedFavCollection(profilePicture);
          } else {
            setSelectedFavCollection(data[0] ?? null);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };

    if (auth && metamaskAddress) {
      checkCollections();
    }
  }, []);

  return (
    <FavoriteCollectionsContext.Provider value={[favCollections, setFavCollections, selectedFavCollection, setSelectedFavCollection]}>
      {children}
    </FavoriteCollectionsContext.Provider>
  );
};