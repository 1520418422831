import LayoutHome from "../layouts/LayoutHome";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import axios from "axios";
import LoadingSpinner from "../partials/LoadingSpinner";
import toast, { Toaster } from "react-hot-toast";
import WhitelistChecker from "../partials/whitelist/WhitelistChecker";

function LandingPage() {
  const inputWhitelistAddress = useRef();
  const [isOpenWhitelist, setIsOpenWhitelist] = useState(false);
  const [isWhitelisted, setIsWhitelisted] = useState(undefined);
  const [showVideo, setShowVideo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleCheckWhitelist = () => {
    if (inputWhitelistAddress.current.value === "") {
      toast.error("Wallet address is required");
      return;
    }

    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_KHUGA_API_BASE_URL}/whitelist-status/${inputWhitelistAddress.current.value}`)
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status === "success") {
          setIsWhitelisted(res?.data?.data?.whitelisted);
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Error occured!");
      });
  };

  console.log(isWhitelisted);

  return (
    <LayoutHome>
      {isLoading && <LoadingSpinner />}

      <div
        className="fixed right-4 bottom-16 md:right-8 md:bottom-20 z-10 bg-[#fc6957] text-white border-4 border-white rounded-full px-6 py-3 cursor-pointer flex items-center space-x-2 font-cursive text-sm shadow-xl hover:-translate-y-1 hover:shadow-2xl transition-all"
        onClick={() => setShowVideo(true)}
      >
        <Icon icon="akar-icons:youtube-fill" className="w-5 h-5" />
        <span>Roadmap</span>
      </div>

      {/* <WhitelistChecker position="fixed" /> */}

      <div className="w-full relative h-screen overflow-hidden cover-landing">
        <div className="absolute inset-x-0">
          <img src={`${process.env.PUBLIC_URL}/images/logo/logo-green.webp`} alt="Logo" className="h-32 sm:h-40 md:h-52 lg:h-64 xl:h-64 mx-auto mt-32" />
          <div className="mt-6 flex justify-center items-center space-x-4">
            {/* <a href="https://discord.com/invite/khuga" target="_blank" className="hover:contrast-200">
              <img src={`${process.env.PUBLIC_URL}/images/social/discord.svg`} className="h-10 md:h-12" alt="Discord" />
            </a>
            <a href="https://twitter.com/khuganft" target="_blank" className="hover:contrast-200">
              <img src={`${process.env.PUBLIC_URL}/images/social/twitter.svg`} className="h-10 md:h-12" alt="Twitter" />
            </a>
            <a href="#" className="hover:brightness-200">
              <img src={`${process.env.PUBLIC_URL}/images/social/me.svg`} className="h-10 md:h-12" alt="ME" />
            </a> */}
          </div>
        </div>
      </div>

      <div className={`w-full h-screen fixed inset-0 z-30 bg-black/90 p-4 items-center ${showVideo ? "flex" : "hidden"}`}>
        <div className="absolute right-4 top-4 cursor-pointer" onClick={() => setShowVideo(false)}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </div>
        <div className="max-w-4xl mx-auto w-full">
          <iframe
            className="w-full aspect-video"
            src={showVideo ? "https://www.youtube.com/embed/IhfY6gGbF8U?autoplay=1" : ""}
            title="Khuga"
            frameBorder="0"
            autoPlay="1"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>

      <Transition appear show={isOpenWhitelist} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsOpenWhitelist(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="relative w-full max-w-xl transform rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-xl font-cursive mb-4">
                    Check Whitelist
                  </Dialog.Title>
                  <div className="my-2 flex items-center space-x-2">
                    <input ref={inputWhitelistAddress} type="text" className="flex-1 rounded-md border-primary-800" placeholder="Wallet Address" />
                    <button
                      className="shrink-0 bg-primary-800 text-white font-bold px-4 py-2.5 rounded-md hover:contrast-125"
                      onClick={() => handleCheckWhitelist()}
                    >
                      Check
                    </button>
                  </div>
                  {isWhitelisted !== undefined && isWhitelisted === true && <p className="font-bold text-green-500">Wallet address has been registered!</p>}
                  {isWhitelisted !== undefined && isWhitelisted === false && (
                    <p className="font-bold text-red-500">
                      Wallet address not registered!{" "}
                      <a href={`${process.env.PUBLIC_URL}/whitelist`} className="text-blue-500 underline">
                        Register now!
                      </a>
                    </p>
                  )}
                  <div
                    onClick={() => setIsOpenWhitelist(false)}
                    className="cursor-pointer p-1.5 bg-red-500 text-white absolute w-8 h-8 rounded-full -right-3 -top-3"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512">
                      <path
                        fill="currentColor"
                        d="m289.94 256l95-95A24 24 0 0 0 351 127l-95 95l-95-95a24 24 0 0 0-34 34l95 95l-95 95a24 24 0 1 0 34 34l95-95l95 95a24 24 0 0 0 34-34Z"
                      />
                    </svg>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* <footer className="absolute bottom-0 w-full bg-black text-white">
        <div className="max-w-6xl mx-auto px-4 pb-2 sm:pb-4 relative z-10 text-center md:text-left">
          <span className="font-bold text-xs">©2023 Khuga NFT by Khuga Labs</span>
        </div>
      </footer> */}
      <footer className="fixed bottom-0 inset-x-0 bg-white text-center p-4 text-xs text-gray-800">©2023 Khuga NFT by Khuga Labs</footer>

      <Toaster />
    </LayoutHome>
  );
}

export default LandingPage;
