import axios from "axios";
import axiosRetry, { isNetworkOrIdempotentRequestError }  from "axios-retry";

const covalentAbortController = new AbortController();
const internalAPIClientAbortController = new AbortController();

const covalentAPIClient = axios.create({
    baseURL: "https://api.covalenthq.com/",
    headers: {
        Accept: "application/json",
    },
    auth: {
        username: process.env.REACT_APP_COVALENT_API_CKEY 
    },
    signal: covalentAbortController.signal 
});

axiosRetry(covalentAPIClient, {
    retries: 3,
    retryDelay: axiosRetry.exponentialDelay,
    retryCondition: (c) => (isNetworkOrIdempotentRequestError(c) || c.response.status === 429)
});

const internalAPIClient = axios.create({
  baseURL: process.env.REACT_APP_KHUGA_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "x-auth-khuga": localStorage.getItem("x-auth-khuga") || "",
  },
  signal: internalAPIClientAbortController.signal,
});

internalAPIClient.interceptors.response.use(
  (resp) => {
    if (resp?.headers?.["x-auth-khuga"] || resp?.data?.data?.khuga_token) {
      localStorage.setItem("x-auth-khuga", resp?.headers?.["x-auth-khuga"] ?? resp?.data?.data?.khuga_token);
      localStorage.setItem("x-user-khuga", JSON.stringify(resp?.data?.data));
    }
    return resp;
  },
  (err) => {
    // return err.response;
    if (err.response.status === 401) {
      localStorage.removeItem("x-auth-khuga");
      localStorage.removeItem("x-user-khuga");
      localStorage.removeItem("metamask_address");
      if (!window.location.href.endsWith("auth")) {
        window.location.href = `${process.env.PUBLIC_URL}/auth`;
      }
    } else {
      return err.response;
    }
  }
);

axiosRetry(internalAPIClient, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: (c) => isNetworkOrIdempotentRequestError(c) || c.response.status === 429,
});

export const useAuthenticatedHttpClient = () => {
  return {
    covalentAbortController,
    covalentAPIClient,
    internalAPIClient,
    internalAPIClientAbortController,
  };
};
