export default function TraitSelected({name, value}) {
    return (
      <button
        className="inline-flex items-center gap-2 bg-gray-300 px-3 py-2 rounded-md hover:contrast-75 text-sm font-semibold border border-gray-400"
      >
        <span>{name}: {value}</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
          <path fill="none" stroke="currentColor" stroke-linecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    );
}