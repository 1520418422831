import { Icon } from "@iconify/react";
import React, { useContext, useEffect, useState } from "react";
import { FavoriteCollectionsContext } from "../../context/FavoriteCollectionsContext";
import { UserContext } from "../../context/UserContext";
import { animated, easings, useSpring } from "@react-spring/web";
import MiawwhallaCollections from "./Collections";
import { useAuthenticatedHttpClient } from "../../utils/authenticated-api-call";
import { filter, first } from "lodash";

function ProfileBox() {
  const metamaskAddress = localStorage.getItem("metamask_address");
  const { internalAPIClient } = useAuthenticatedHttpClient();
  const [showModal, setShowModal] = useState(false);
  const [showMetadata, setShowMetadata] = useState(false);
  const [currentUser, setCurrentUser] = useContext(UserContext);
  const [favCollections, setFavCollections, selectedFavCollection, setSelectedFavCollection] = useContext(FavoriteCollectionsContext);
  const [emptyFavorite, setEmptyFavorite] = useState([]);

  useEffect(() => {
    if (currentUser?.avatar_id) {
      const asProfilePicture = filter(favCollections, (i) => i.metadata.id == currentUser.avatar_id);
      const notProfilePicture = filter(favCollections, (i) => i.metadata.id != currentUser.avatar_id);
      const orderedFavCollections = [...asProfilePicture, ...notProfilePicture];
      if (orderedFavCollections.length > 0) {
        setFavCollections(orderedFavCollections);
      }
    }
  }, [currentUser]);

  useEffect(() => {
    let emptyFav = [];
    for (let i = 0; i < 5 - favCollections.length; i++) {
      emptyFav.push(<div key={i} className="w-[90%] aspect-square rounded-xl bg-[#7575A0] cursor-not-allowed"></div>);
    }
    setEmptyFavorite(emptyFav);
  }, [favCollections]);

  const [springs, api] = useSpring(() => ({
    from: { rotate: 0 },
  }));

  const handleSetProfilePicture = (id) => {
    setCurrentUser((prevState) => ({ ...prevState, ...{avatar: `https://khuga.io/khuga-preview/${id}.webp`, avatar_id: id} }));
    internalAPIClient.post(`${process.env.REACT_APP_KHUGA_API_BASE_URL}/nft-ownerships/${metamaskAddress}/profile-picture/${id}`).catch((e) => {
      console.log(e);
    });
  };

  const handleSelectCollection = (i) => {
    setSelectedFavCollection(i);
    api.start({
      from: {
        rotate: 0,
      },
      to: [{ rotate: -7 }, { rotate: 7 }, { rotate: 0 }],
      config: {
        duration: 250,
        easing: easings.linear,
      },
    });
  };

  return (
    <>
      <img src={`${process.env.PUBLIC_URL}/images/miawwhalla/profile-box.webp`} alt="Profile" className="w-full" />
      {/* Sidebar */}
      <div
        className="absolute left-[6%] top-[5%] w-[20%] h-full bg-contain bg-no-repeat"
        style={{ backgroundImage: `url('${process.env.PUBLIC_URL}/images/miawwhalla/profile-bar.webp')` }}
      >
        <div className="absolute top-[7%] left-[19%] w-[60%] h-3/4">
          <div className="space-y-3 mb-4 scrollbar-thin scrollbar-thumb-[#ADADF0] scrollbar-track-[#7575A0] scrollbar-rounded-lg ml-2">
            {favCollections.length > 0 &&
              favCollections.map((i) => (
                <img
                  src={`https://khuga.io/khuga-preview/${i.metadata.id}.webp`}
                  alt={i.metadata.name}
                  key={i.id}
                  className={`w-[90%] aspect-square rounded-md cursor-pointer hover:border-4 border-transparent hover:border-[#ADADF0] transition-all ${
                    i.id == selectedFavCollection?.id && "border-4 border-[#ADADF0]"
                  }`}
                  onClick={() => handleSelectCollection(i)}
                />
              ))}
            {emptyFavorite}
          </div>
          <button
            onClick={() => setShowModal(true)}
            className="w-full font-cursive text-sm bg-[#121F58] text-white rounded-md py-1 inline-block hover:scale-105 transition-all"
          >
            Show All
          </button>
        </div>
      </div>

      {/* Main Area */}
      <animated.div
        className="absolute top-[10%] left-[28%] w-[65%] origin-top"
        style={{
          ...springs,
        }}
      >
        {selectedFavCollection?.metadata ? (
          <div className="w-full">
            <img src={selectedFavCollection?.metadata?.image_url} alt={selectedFavCollection?.metadata?.name} className="w-full" />
          </div>
        ) : (
          <div className="w-full aspect-square flex items-center justify-center">
            <div className="text-center">
              <h4 className="font-bold text-lg font-cursive mb-4">
                You don't have any
                <br />
                favorite collection
              </h4>
              <button
                onClick={() => setShowModal(true)}
                className="w-full font-cursive text-sm bg-[#121F58] text-white rounded-md px-4 py-2 inline-block hover:scale-105 transition-all"
              >
                Select your Collections
              </button>
            </div>
          </div>
        )}
      </animated.div>

      {/* Set as favorite */}
      {/* <div className="absolute top-0 right-4">
        <img src={`${process.env.PUBLIC_URL}/images/miawwhalla/attr.webp`} alt="Attr" className="w-20" />

        <div
          className="absolute inset-0 flex justify-center items-center pt-3 cursor-pointer hover:scale-110 transition-all active:scale-100"
          onClick={() => handleSetProfilePicture(selectedFavCollection?.collection?.image)}
        >
          {currentUser?.avatar == selectedFavCollection?.collection?.image ? (
            <Icon icon="heroicons-solid:heart" className="w-10 h-10 text-red-600" />
          ) : (
            <Icon icon="heroicons-outline:heart" className="w-10 h-10" />
          )}
        </div>
      </div> */}

      {/* Metadata */}
      {showMetadata && (
        <div className="absolute top-10 right-20 w-64 bg-white/60 backdrop-blur rounded-xl">
          {/* <img src={`${process.env.PUBLIC_URL}/images/miawwhalla/window.webp`} alt="window" className="absolute top-0 right-0 w-64 opacity-100" /> */}
          <div className="relative z-10 px-10 py-6 space-y-1 text-[#141F58]">
            {selectedFavCollection?.metadata?.race && (
              <div className="-space-y-1">
                <h6 className="text-sm font-medium">Race:</h6>
                <p className="font-bold">{selectedFavCollection?.metadata?.race}</p>
              </div>
            )}
            {selectedFavCollection?.metadata?.cloth && (
              <div className="-space-y-1">
                <h6 className="text-sm font-medium">Cloth:</h6>
                <p className="font-bold">{selectedFavCollection?.metadata?.cloth}</p>
              </div>
            )}
            {selectedFavCollection?.metadata?.eyes && (
              <div className="-space-y-1">
                <h6 className="text-sm font-medium">Eyes:</h6>
                <p className="font-bold">{selectedFavCollection?.metadata?.eyes}</p>
              </div>
            )}
            {selectedFavCollection?.metadata?.hand && (
              <div className="-space-y-1">
                <h6 className="text-sm font-medium">Hand:</h6>
                <p className="font-bold">{selectedFavCollection?.metadata?.hand}</p>
              </div>
            )}
            {selectedFavCollection?.metadata?.head && (
              <div className="-space-y-1">
                <h6 className="text-sm font-medium">Head:</h6>
                <p className="font-bold">{selectedFavCollection?.metadata?.head}</p>
              </div>
            )}
            {selectedFavCollection?.metadata?.mouth && (
              <div className="-space-y-1">
                <h6 className="text-sm font-medium">Mouth:</h6>
                <p className="font-bold">{selectedFavCollection?.metadata?.mouth}</p>
              </div>
            )}
            {selectedFavCollection?.metadata?.background && (
              <div className="-space-y-1">
                <h6 className="text-sm font-medium">Background:</h6>
                <p className="font-bold">{selectedFavCollection?.metadata?.background}</p>
              </div>
            )}
            {selectedFavCollection?.metadata?.legendary && (
              <div className="-space-y-1">
                <h6 className="text-sm font-medium">Legendary:</h6>
                <p className="font-bold">{selectedFavCollection?.metadata?.legendary}</p>
              </div>
            )}
          </div>
        </div>
      )}

      <div className="absolute bottom-2 right-12">
        <img src={`${process.env.PUBLIC_URL}/images/miawwhalla/aksen.webp`} className="w-2/3" alt="" />
      </div>

      {selectedFavCollection?.metadata?.name && (
        <>
          <div
            className="cursor-pointer hover:scale-105 active:scale-100 transition-all absolute top-0 right-20 w-72 h-24 bg-contain bg-no-repeat text-center flex items-center justify-center font-cursive text-xl pt-0"
            style={{ backgroundImage: `url('${process.env.PUBLIC_URL}/images/miawwhalla/bg-name.webp')` }}
            onClick={() => setShowMetadata(!showMetadata)}
          >
            <h6 className="text-[#141F58] absolute top-4">{selectedFavCollection?.metadata?.name}</h6>
          </div>
          <button
            className="absolute right-24 top-1.5 cursor-pointer hover:scale-110 transition-all active:scale-100"
            onClick={() => handleSetProfilePicture(selectedFavCollection?.metadata?.id)}
          >
            {currentUser?.avatar == `https://khuga.io/khuga-preview/${selectedFavCollection?.metadata?.id}.webp` ? (
              <Icon icon="heroicons-solid:heart" className="w-10 h-10 text-red-600" />
            ) : (
              <Icon icon="heroicons-outline:heart" className="w-10 h-10" />
            )}
          </button>
        </>
      )}

      {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 z-40 flex items-center justify-center">
          <div className="absolute inset-0 bg-black/50 backdrop-blur"></div>
          <div className="relative w-full max-w-6xl mx-auto px-4 h-[calc(100vh_-_20%)]">
            <button
              className="absolute z-20 -right-1 -top-6 bg-red-500 p-3 rounded-full text-white shadow hover:shadow-xl transition-all"
              onClick={() => setShowModal(false)}
            >
              <Icon icon="heroicons:x-mark" className="w-7 h-7" />
            </button>
            <div className="bg-[#D2D5E2]/70 rounded-lg border border-gray-600 h-full">
              <MiawwhallaCollections />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ProfileBox;
