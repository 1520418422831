import { Icon } from "@iconify/react";
import { animated, useSpring } from "@react-spring/web";
import { Link } from "react-router-dom";
import LayoutMiawhalla from "../../layouts/LayoutMiawhalla";
import ProfileBox from "../../partials/miawwhalla/ProfileBox";

function MiawwhallaDashboard() {
  const [springs, api] = useSpring(() => ({
    from: { x: 800 },
    to: { x: 0 },
  }));

  return (
    <LayoutMiawhalla>
      <div className="grid grid-cols-2 items-center">
        <div className="relative">
          <ProfileBox />
        </div>
        <animated.div
          style={{
            ...springs,
          }}
        >
          <Link to={`${process.env.PUBLIC_URL}/miawwhalla/profile`}>
            <img
              src={`${process.env.PUBLIC_URL}/images/miawwhalla/btn-inven.webp`}
              alt="Profile"
              className="w-[80%] mb-4 transition-all active:scale-95 hover:scale-105 origin-right"
            />
          </Link>
          <div className="relative cursor-not-allowed">
            <img
              src={`${process.env.PUBLIC_URL}/images/miawwhalla/btn-enter-disabled.webp`}
              alt="Enter"
              className="w-[80%] ml-auto transition-all origin-right"
            />
          </div>
        </animated.div>
      </div>
    </LayoutMiawhalla>
  );
}

export default MiawwhallaDashboard;
