import LayoutMiawhalla from "../../layouts/LayoutMiawhalla";
import Inventory from "../../partials/miawwhalla/Inventory";
import ProfileBox from "../../partials/miawwhalla/ProfileBox";
import { animated, useSpring } from "@react-spring/web";
import { Icon } from "@iconify/react";
import { Link, useNavigate } from "react-router-dom";

function MiawwhallaProfile() {
  const navigate = useNavigate();

  const [springs, api] = useSpring(() => ({
    from: { x: 1000 },
    to: { x: 0 },
  }));

  const handleBack = () => {
    api.start({
      from: { x: 0 },
      to: { x: 1000 },
    });
    setTimeout(() => {
      return navigate(`${process.env.PUBLIC_URL}/miawwhalla`);
    }, 300)
  }

  return (
    <LayoutMiawhalla>
      <div className="grid grid-cols-2 items-center">
        <div className="relative">
          <ProfileBox />
        </div>
        <animated.div
          className="relative w-full h-full"
          style={{
            ...springs,
          }}
        >
          <div className="text-white/60 hover:text-white absolute z-10 my-auto h-full flex items-center -left-4 cursor-pointer hover:scale-110 transition-all" onClick={handleBack}>
            <Icon icon="mdi:chevron-right" className="w-20 h-20" />
          </div>
          <Inventory />
        </animated.div>
      </div>
    </LayoutMiawhalla>
  );
}

export default MiawwhallaProfile;
